import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, getEventById, getEventInfosByEventId, getTicketTypesByEventId, getTicketTypeInfosByTicketTypeId, getTicketTypeNumberOfAvailableTickets } from "ticketino-api-client";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Loading from "./components/Loading";

const Home = () => {
    const [eventId, setEventId] = useState([]);
    const [event, setEvent] = useState({});
    const [ticketTypes, setTicketTypes] = useState([]);
    const [selectedTicketTypes] = useState([]);
    const [disabled, setDisabled] = useState(true);
    const [orderId, setOrderId] = useState();
    const [moreInfo, setMoreInfo] = useState({});
    const [loading, setLoading] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [posId, setPosId] = useState();

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // setting the base url of the npm package api calls
    api.defaults.baseURL = baseUrl;

    // fetching resources
    const [resources, setResources] = useState({});

    // fetching params
    const { language } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    useEffect(() => {
        loadToken();

        // clear the addedPromocodes in the session
        let array = [];
        sessionStorage.setItem("AddedPromotions", JSON.stringify(array));
    }, []); //only on first page load

    useEffect(() => {
        requestResources();

        // so it wont get called on first load
        if (loaded) {
            startOrder(posId, eventId);
        }
    }, [language]); //everytime language is changed

    useEffect(() => {
        if (orderId) {
            loadEvent(eventId, orderId)
        }
    }, [orderId]); //gets called when an order is started

    const loadToken = async () => {
        await axios.get("form/token").then((res) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;
            api.defaults.headers.common['Authorization'] = "Bearer " + res.data;

            sessionStorage.setItem("token", res.data);

            requestFormSettings();
        });
    };

    const requestFormSettings = async () => {
        await axios.get(`form/formsettings`).then((res) => {
            // setting the posID and eventID
            setPosId(res.data.posId);
            setEventId(res.data.eventId);

            startOrder(res.data.posId, res.data.eventId);
        });
    };

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    const startOrder = async (posId, eventId) => {
        let order = {
            affiliateShopId: null,
            currency: "CHF",
            tenantId: 1,
            languageId: languageId,
            pointOfSaleId: posId,
            abbreviation: "",
            paymentType: 1,
        };

        await axios
            .post(`${baseUrl}/ShopBasket/Order`, order)
            .then((res) => {
                sessionStorage.setItem("orderId", res.data.id);
                setOrderId(res.data.id);
                setLoaded(true);
            })
            .catch((error) => console.error(error.response.data));
    };

    const loadEvent = async (eventId, orderId) => {
        try {
            setLoading(true);

            // Event
            const event = await getEventById(eventId);
            const infos = await getEventInfosByEventId(eventId);

            // sorting it according to the language
            const eventInfo = infos.eventInfos.find(info => info.languageId == languageId);

            // setting the eventInfo object in the info attribute of event
            const updatedEventInfo = { ...event, info: eventInfo };

            // TicketType
            const ticketTypes = await getTicketTypesByEventId(eventId);

            const updatedTicketTypes = await Promise.all(
                ticketTypes.map(async (ticketType) => {
                    const info = await getTicketTypeInfosByTicketTypeId(ticketType.id);

                    // sorting it according to the language
                    const ticketTypeInfo = info.find(info => info.languageId == languageId);

                    const ticketTypeAvailability = await getTicketTypeNumberOfAvailableTickets(ticketType.id, orderId);

                    // setting the ticketTypeInfo and ticketTypeAvailability object as attributes in ticketType (info and availability)
                    return { ...ticketType, info: ticketTypeInfo, availability: ticketTypeAvailability }
                })
            )

            // setting the values in the end
            setEvent(updatedEventInfo);
            setTicketTypes(updatedTicketTypes);
            setLoading(false);

        } catch (error) {
            console.error(error);
        }
    }

    const addTicketToBasket = async () => {
        let addTicketTypes = [];

        addTicketTypes = selectedTicketTypes.filter((stt) => stt.quantity !== "0");

        await axios
            .post(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
                ticketsToAdd: addTicketTypes,
            })
            .then(() => {
                navigate(`/${language}/address`);
            })
            .catch((error) => {
                console.error(error.response.data);
            });
    };

    const onSubmit = async () => {
        await addTicketToBasket();
    };
    const onTicketTypeChange = (e, ticketTypeId) => {
        const obj = {
            ticketTypeId: ticketTypeId,
            quantity: e.target.value,
        };

        const existingTicketType = selectedTicketTypes.find(
            (t) => t.ticketTypeId === ticketTypeId
        );

        if (existingTicketType) {
            existingTicketType.quantity = e.target.value;
        } else {
            selectedTicketTypes.push(obj);
        }

        // calcualate quantity and validate
        let quantity = 0;

        selectedTicketTypes.map(s => {
            quantity += s.quantity * 1;
        });

        if (quantity > 0) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    };

    const textContainer = () => {
        let eventStart = "";

        if (event.start) {
            const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            eventStart = new Date(event?.start).toLocaleDateString(`de`, options);
        }

        return (
            <div className="fs-6 text-container mb-3 row">
                <div className="col-12 text-start">{resources?.BuyTickets}</div>
            </div>
        )
    }

    const mapTicketTypes = () => {
        let ticketTypesSorted = [];

        if (ticketTypes && ticketTypes.length > 0) {
            ticketTypesSorted = [...ticketTypes].sort((a, b) => a.sortOrder - b.sortOrder);
        }

        return (
            ticketTypes &&
            ticketTypes.length > 0 &&
            ticketTypesSorted.map((tt, index) => (
                <div key={index}>
                    {
                        tt.availability?.status === 0 &&
                        (
                            <div className="row pt-2 pb-2">
                                <div className="col-md-8 col-6">
                                    <label className="fs-6">
                                        {tt.info?.name}
                                    </label>
                                    <div className="description">
                                        <span onClick={() => setMoreInfo({ [tt.id]: !moreInfo[tt.id] })}>{resources.OtherInfos} <i className={"bi bi-chevron-" + (moreInfo[tt.id] ? "down" : "up")}></i></span><br></br>
                                        {moreInfo[tt.id] && tt.info?.description}
                                    </div>
                                </div>
                                <div className="col-md-2 col-3 text-center">
                                    <label>
                                        {tt.currency} {tt.price}.-
                                    </label>
                                </div>
                                <div className="col-md-2 col-3">
                                    <select
                                        className="form-select"
                                        onChange={(e) => onTicketTypeChange(e, tt.id)}
                                    >
                                        {Array.from(
                                            Array(
                                                tt.availability?.availableTickets + 1
                                            ),
                                            (e, i) => {
                                                return (
                                                    <option key={i} value={i}>
                                                        {i}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </select>
                                </div>
                            </div>
                        )}
                </div>
            ))
        );
    };

    return (
        <div className="container wrapper">
            <Header resources={resources} showLanguage={true} />
            <div className="mt-4">
                <div>
                    <p className="fs-4 fw-bold mb-1">{event.info?.name}</p>
                    <p className="fs-5 mb-1">{event.info?.shortDescription}</p>
                    <p className="fs-6">{resources?.Description}</p>
                </div>
                <div>
                    <div className="container">
                        {textContainer()}
                    </div>
                    <div>{!loading ? mapTicketTypes() : <Loading bgColor="#fff" color="#eb6983" />}</div>
                </div>
                <div className="row mt-4 mb-4">
                    <div className="col-md-6 col-12">
                        <p className="fs-6">*{resources?.Note}</p>
                    </div>
                    <div className="col-md-6 col-12 text-end">
                        <button className="button" disabled={disabled} onClick={() => onSubmit()}>
                            {resources?.Next}
                        </button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Home