import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import './css/style.css';

import Home from "./views/Home";
import Address from "./views/Step10_Address";
import Checkout from "./views/Step20_Checkout";
import Confirmation from "./views/Step30_Confirmation";

function App() {
  return (
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<Navigate to="/de/home" replace />} />
              <Route path="/:language/home" element={<Home />} />
              <Route path="/:language/address" element={<Address />} />
              <Route path="/:language/checkout" element={<Checkout />} />
              <Route path="/:language/confirmation/:orderId" element={<Confirmation />} />
          </Routes>
      </BrowserRouter>
  );
}

export default App;
