import React from 'react'

import flyer from "../../images/Eventflyer-Bild_196x270.jpg";
import banner from "../../images/banner.jpg";
import { useNavigate, useParams } from 'react-router-dom';

const Header = (props) => {

    let navigate = useNavigate();

    const { language } = useParams();

    const currentPathname = window.location.pathname;

    const handleChangeLanguage = (e) => {
        const newLanguage = e.target.value;
        const newPathname = currentPathname.replace(`/${language}/`, `/${newLanguage}/`);

        navigate(newPathname);
    }

    return (
        <div>
            <div className='text-end pt-3' style={{ justifyContent: "right", display: "flex" }}>
                <div style={{ minWidth: "70px" }}>
                    <select
                        className='language-select form-select fw-semibold'
                        onChange={(e) => handleChangeLanguage(e)}
                    >
                        <option value="de" selected={language == "de"}>de</option>
                        <option value="en" selected={language == "en"}>en</option>
                    </select>
                </div>
            </div>
            <div>
                <img className='text-center flyer' src={flyer} alt="expovina flyer"></img>
            </div>
            <div className='row'>
                <div className='col-12 fill'>
                    <img className='img-fluid' src={banner} alt="expovina banner"></img>
                </div>
            </div>
        </div>
    )
}

export default Header